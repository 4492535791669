<template>
  <div>
    <div v-if="!loading">
      <div v-if="user && user.facilities && user.facilities.length && !loading">
        <v-card
          class="add-link default-height mb-6"
          color="transparent"
          data-cy="addExperience"
          elevation="0"
          @click="addEntry"
        >
          <v-card-text class="text-center text-primary text-body-1">
            <v-icon>mdi-plus-circle-outline</v-icon>
            <span class="text-body-1 pt-2 pl-2"
              >{{ !user.position?.length ? 'Rotation' : 'Stelle' }} hinzufügen</span
            >
          </v-card-text>
        </v-card>
      </div>
      <v-alert
        v-else
        border="start"
        type="error"
        color="error"
        elevation="2"
      >
        <div>Bitte geben Sie Ihre Klinik in den Einstellungen an.</div>
      </v-alert>
      <schedule-history-add-default
        v-if="user"
        :user-id="user.id"
        :entries="entries"
        @change="defaultChanged"
      />
    </div>
    <v-container class="pa-0">
      <v-row>
        <v-fade-transition leave-absolute>
          <v-col
            v-if="loading"
            key="skeleton"
            cols="12"
            class="mb-0"
          >
            <v-card
              :elevation="1"
              data-cy="addedPlan"
              :class="'mb-0 sectionbordersecondary'"
            >
              <v-skeleton-loader type="list-item-two-line" />
            </v-card>
          </v-col>
        </v-fade-transition>
      </v-row>
      <v-row>
        <v-col
          v-for="entry in filteredEntries"
          :key="entry.id"
          cols="12"
          data-cy="filteredEntries"
        >
          <schedule-history-card
            :entry="entry"
            @click="setEditEntry(entry)"
          />
        </v-col>
      </v-row>
      <v-bottom-sheet
        v-model="hasEditEntry"
        scrollable
        data-cy="bottomSheet"
        close-on-back
      >
        <v-card
          v-if="editEntry"
          class="text-center"
        >
          <v-card-text class="pa-2">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editEntry.start"
                    label="Startdatum"
                    hide-details="auto"
                    variant="outlined"
                    data-cy="StartDate"
                    :max="limitDateFuture"
                    min="1980-01-01"
                    type="date"
                    :error-messages="startErrors"
                    @blur="v$.editEntry.start.$touch()"
                    @update:model-value="setEditEntryChanged"
                  >
                    <!-- <v-icon
                      v-if="v$.editEntry.start.$error"
                      color="error"
                      >mdi-alert-circle-outline</v-icon
                    > -->
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-menu
                    ref="endMenu"
                    v-model="endMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="400"
                    width="auto"
                    offset="20"
                  >
                    <template #activator="{ props }">
                      <v-text-field
                        v-model="editEntry.end"
                        label="Enddatum"
                        v-bind="props"
                        data-cy="EndDate"
                        hide-details="auto"
                        :max="limitDateFuture"
                        :min="editEntry.start"
                        type="date"
                        variant="outlined"
                        :error-messages="endErrors"
                        @change="v$.editEntry.end.$touch()"
                        @update:model-value="setEditEntryChanged"
                      >
                        <!--                         <v-icon
                          v-if="v$.editEntry.end.$error"
                          color="error"
                          >mdi-alert-circle-outline</v-icon
                        > -->
                      </v-text-field>
                    </template>
                    <v-card
                      v-if="!hasRole(['ROTATING_STAFF'])"
                      data-cy="addToEndCards"
                    >
                      <v-container>
                        <v-row dense>
                          <v-col>
                            <v-btn
                              block
                              size="small"
                              variant="outlined"
                              color="primary"
                              @click="addToEnd({ weeks: 2 })"
                            >
                              + 2 Wochen
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              block
                              size="small"
                              variant="outlined"
                              color="primary"
                              @click="addToEnd({ months: 1 })"
                            >
                              + 1 Monat
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              block
                              size="small"
                              variant="outlined"
                              color="primary"
                              @click="addToEnd({ months: 2 })"
                            >
                              + 2 Monate
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col>
                            <v-btn
                              block
                              size="small"
                              variant="outlined"
                              color="primary"
                              @click="addToEnd({ months: 3 })"
                            >
                              + 3 Monate
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              block
                              size="small"
                              variant="outlined"
                              color="primary"
                              @click="addToEnd({ months: 6 })"
                            >
                              + 6 Monate
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              block
                              size="small"
                              variant="outlined"
                              color="primary"
                              @click="addToEnd({ years: 1 })"
                            >
                              + 1 Jahr
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editEntry.percentage"
                    label="Arbeitszeitmodell"
                    placeholder="100%"
                    type="number"
                    min="0"
                    max="100"
                    step="1"
                    suffix="%"
                    hide-details="auto"
                    data-cy="activityModel"
                    variant="outlined"
                    :error="v$.editEntry.percentage.$error"
                    @blur="v$.editEntry.percentage.$touch()"
                    @update:model-value="setEditEntryChanged"
                  />
                </v-col>

                <v-col
                  v-if="
                    getPremiumFeatures().includes('INTERNAL_ROTATION') &&
                    (!includeSpecialities || !includeSpecialities.length)
                  "
                  cols="12"
                  md="6"
                >
                  <v-btn-toggle
                    v-model="toggleType"
                    divided
                    color="primary"
                    style="width: 100%"
                  >
                    <v-btn
                      style="width: 50%"
                      value="internal"
                      >interner Einsatz</v-btn
                    >
                    <v-btn
                      style="width: 50%"
                      value="external"
                      >Rotation</v-btn
                    >
                  </v-btn-toggle>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-if="editEntry.rotation_type !== 'Rotation'"
                    v-model="editEntry.unit"
                    :items="filteredUnits"
                    item-value="id"
                    item-title="name"
                    label="Einheit"
                    data-cy="activityUnit"
                    hide-details="auto"
                    variant="outlined"
                    :error-messages="unitErrors"
                    clearable
                    @blur="v$.editEntry.unit.$touch()"
                    @update:model-value="setEditEntryChanged(setSpeciality(editEntry.unit))"
                  />
                  <select-facility-list
                    v-else-if="editEntry.rotation_type === 'Rotation'"
                    v-model="editEntry.facility"
                    label="Klinik"
                    class="text-left"
                    return-object
                    hide-details="auto"
                    variant="outlined"
                    :fields="['specialities.speciality_id.id', 'specialities.speciality_id.name']"
                    :filter="facilityFilter"
                    :additional-items="[{ id: 'other', name: 'andere Rotation' }]"
                    :select-first="includeSpecialities && !!includeSpecialities.length"
                    :error-messages="facilityErrors"
                    @blur="v$.editEntry.facility.$touch()"
                    @update:model-value="
                      setEditEntryChanged(setSpecialityById(editEntry.facility.specialities))
                    "
                  />
                </v-col>
                <v-col
                  v-if="editEntry.unit === 'other'"
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="editEntry.rotation_type"
                    :items="rotationTypes"
                    label="Tätigkeitsbereich"
                    data-cy="activityField"
                    hide-details="auto"
                    variant="outlined"
                    :error-messages="rotation_typeErrors"
                    @blur="v$.editEntry.rotation_type.$touch()"
                    @update:model-value="setEditEntryChanged((editEntry.unit = null))"
                  />
                </v-col>

                <v-col
                  v-if="editEntry.unit === 'other' || editEntry.facility?.id === 'other'"
                  cols="12"
                  md="6"
                >
                  <select-speciality
                    v-model="editEntry.specialities"
                    hide-details="auto"
                    multiple
                    return-object
                    :error-messages="specialitiesErrors"
                    field-name="speciality_id"
                    label="Fachrichtung"
                    variant="outlined"
                    data-cy="chooseSpeciality"
                    @blur="v$.editEntry.specialities.$touch()"
                    @update:model-value="setEditEntryChanged"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="
                editEntry?.id && !(editEntry.status === 'confirmed' && hasRole(['ROTATING_STAFF']))
              "
              variant="outlined"
              :class="$vuetify.display.xs ? 'px-7' : 'px-10'"
              color="red-darken-1"
              size="large"
              rounded
              data-cy="delete"
              @click="openDeleteDialog()"
            >
              Löschen
            </v-btn>
            <v-spacer v-if="editEntry?.id" />
            <v-btn
              v-if="editEntryChanged"
              variant="elevated"
              :class="$vuetify.display.xs ? 'px-7' : 'px-10'"
              size="large"
              rounded
              :width="!editEntry?.id ? 250 : ''"
              color="primary"
              data-cy="saveForReview"
              @click="saveEditEntry"
            >
              Speichern
            </v-btn>
            <v-btn
              v-else
              variant="elevated"
              :class="$vuetify.display.xs ? 'px-7' : 'px-10'"
              size="large"
              rounded
              :width="!editEntry?.id ? 250 : ''"
              color="primary"
              data-cy="saveForReview"
              @click="editEntry = null"
            >
              Schließen
            </v-btn>
            <v-spacer />
          </v-card-actions>
          <v-dialog
            v-model="confirmDeleteDialog"
            width="500"
            close-on-back
          >
            <v-card
              class="pt-2 pb-5 px-5"
              append-icon="$close"
            >
              <template #append>
                <v-btn
                  icon="$close"
                  variant="text"
                  data-cy="closeConfirmDeleteDialog"
                  @click="confirmDeleteDialog = false"
                ></v-btn>
              </template>
              <v-card-item class="pt-0">
                <v-row>
                  <v-col>
                    <v-card-text
                      style="line-height: 150%"
                      class="text-h2 text-center pt-0 mt-0"
                    >
                      Möchten Sie diesen Eintrag wirklich löschen?
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card-actions class="">
                  <v-btn
                    color="primary"
                    variant="elevated"
                    rounded
                    size="large"
                    class="px-10 mx-auto"
                    :loading="deleteLoading"
                    data-cy="deleteEntry"
                    @click="deleteEntry"
                  >
                    Eintrag löschen
                  </v-btn>
                </v-card-actions>
              </v-card-item>
            </v-card>
          </v-dialog>
        </v-card>
      </v-bottom-sheet>
    </v-container>
  </div>
</template>
<script>
import { required, between, integer } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { format, add, areIntervalsOverlapping } from 'date-fns'

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    teaser: {
      type: Boolean,
      default: false,
    },
    includeSpecialities: {
      type: Array,
      default: null,
    },
    excludeSpecialities: {
      type: Array,
      default: null,
    },
    external: {
      type: Boolean,
    },
    deleteLoading: {
      type: Boolean,
    },
  },
  emits: ['edit'],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      confirmDeleteDialog: false,
      activePicker: null,
      startMenu: false,
      endMenu: false,
      user: null,
      units: [],
      entries: [],
      facilitiesInFlow: [],
      editEntry: null,
      editEntryChanged: false,
      physician: null,
      loading: true,
      deleteGeneratedLoading: false,
    }
  },
  validations() {
    const validations = {
      editEntry: {
        start: { required },
        end: { required },
        rotation_type: { required },
        percentage: { required, integer, between: between(0, 100) },
        specialities: { required },
        unit: {
          required(value) {
            return this.editEntry.rotation_type === 'Rotation' || !!value
          },
        },
        facility: {
          required(value) {
            return this.editEntry.rotation_type !== 'Rotation' || !!value
          },
        },
      },
    }
    if (this.hasRole(['ROTATING_STAFF'])) {
      validations.editEntry.start.maxValue = (val) => this.parseDate(val) <= new Date()
      validations.editEntry.end.maxValue = (val) => this.parseDate(val) <= new Date()
    }
    if (this.editEntry && this.editEntry.start) {
      validations.editEntry.end.minValue = (val) =>
        this.parseDate(val) > this.parseDate(this.editEntry.start)
    }
    return validations
  },
  computed: {
    startErrors() {
      const errors = []
      if (!this.v$.editEntry.start.$dirty) {
        return errors
      }
      this.v$.editEntry.start.required.$invalid && errors.push('Bitte geben Sie ein Startdatum an.')
      this.v$.editEntry.start.maxValue?.$invalid &&
        errors.push('Sie haben keine Berechtigungen, Rotationen in der Zukunft zu ändern.')
      return errors
    },
    endErrors() {
      const errors = []
      if (!this.v$.editEntry.end.$dirty) {
        return errors
      }
      this.v$.editEntry.end.required.$invalid && errors.push('Bitte geben Sie ein Enddatum an.')
      this.v$.editEntry.end.minValue?.$invalid &&
        errors.push('Enddatum kann nicht vor dem Startdatum liegen.')
      this.v$.editEntry.end.maxValue?.$invalid &&
        errors.push('Sie haben keine Berechtigungen, Rotationen in der Zukunft zu ändern.')
      return errors
    },
    specialitiesErrors() {
      const errors = []
      if (!this.v$.editEntry.specialities.$dirty) {
        return errors
      }
      this.v$.editEntry.specialities.required.$invalid &&
        errors.push('Bitte geben Sie eine Fachrichtung an.')
      return errors
    },
    rotation_typeErrors() {
      const errors = []
      if (!this.v$.editEntry.rotation_type.$dirty) {
        return errors
      }
      this.v$.editEntry.rotation_type.required.$invalid &&
        errors.push('Bitte geben Sie einen Tätigkeitsbereich an.')
      return errors
    },
    unitErrors() {
      const errors = []
      if (!this.v$.editEntry.unit.$dirty) {
        return errors
      }
      this.v$.editEntry.unit.required.$invalid && errors.push('Bitte geben Sie eine Einheit an.')
      return errors
    },
    facilityErrors() {
      const errors = []
      if (!this.v$.editEntry.facility.$dirty) {
        return errors
      }
      this.v$.editEntry.facility.required.$invalid && errors.push('Bitte geben Sie eine Klinik an.')
      return errors
    },
    rotationTypes() {
      const rotationTypes = Object.keys(this.groupByProperty(this.units, 'rotation_types', true))
      return rotationTypes
    },
    hasEditEntry: {
      get() {
        return !!this.editEntry
      },
      set(value) {
        if (!value) {
          this.editEntry = null
        }
      },
    },
    toggleType: {
      get() {
        if (this.editEntry.rotation_type === 'Rotation') {
          return 'external'
        } else {
          return 'internal'
        }
      },
      set(value) {
        if (value === 'external') {
          this.editEntry.rotation_type = 'Rotation'
          this.editEntry.unit = null
        } else {
          this.editEntry.rotation_type = this.rotationTypes.length ? this.rotationTypes[0] : ''
          this.editEntry.facility = null
        }
      },
    },
    filteredEntries() {
      let entries = JSON.parse(JSON.stringify(this.entries))
      if (this.includeSpecialities && this.includeSpecialities.length) {
        entries = entries.filter((entry) =>
          entry.specialities.some((spec) =>
            this.includeSpecialities.includes(spec.speciality_id.id)
          )
        )
      }
      if (this.excludeSpecialities && this.excludeSpecialities.length) {
        entries = entries.filter(
          (entry) =>
            !entry.specialities.some((spec) =>
              this.excludeSpecialities.includes(spec.speciality_id.id)
            )
        )
      }
      if (this.hasRole(['ROTATING_STAFF'])) {
        return entries.filter((entry) => entry.status !== 'proposal')
      } else {
        return entries
      }
    },
    maxPhase() {
      const phases = this.entries.map((entry) => entry.schedule_section.phase)
      return Math.max(...phases)
    },
    limitDateFuture() {
      if (this.hasRole(['ROTATING_STAFF'])) {
        return new Date().toISOString().substring(0, 10)
      } else {
        return null
      }
    },
    filteredUnits() {
      const units = JSON.parse(JSON.stringify(this.units))
      units.push({ id: 'other', name: 'andere Rotation' })
      return units
    },
    facilityFilter() {
      const _and = []
      if (this.facilitiesInFlow && this.facilitiesInFlow.length) {
        _and.push({
          id: {
            _in: this.facilitiesInFlow,
          },
        })
      }
      if (this.includeSpecialities && this.includeSpecialities.length) {
        _and.push({
          specialities: {
            speciality_id: {
              _in: this.includeSpecialities,
            },
          },
        })
      }
      if (_and.length) {
        return { _and }
      } else {
        return null
      }
    },
  },
  watch: {
    deleteLoading(value) {
      if (!value) {
        this.editEntry = null
      }
    },
  },
  async mounted() {
    this.loading = true
    this.user = await this.$cms.request(
      this.$readUser(this.userId, {
        fields: ['id', 'position', 'facilities.id', 'facilities.facility_id'],
      })
    )
    await Promise.all([this.loadEntries(), this.loadUnits(), this.loadFlow()])
      .then(() => {
        if (
          this.$route.query.edit &&
          this.entries?.length &&
          this.hasRole('ADMINISTRATIVE_STAFF')
        ) {
          const editEntry = this.entries.filter((entry) => entry.id === this.$route.query.edit)[0]
          if (editEntry) {
            this.setEditEntry(editEntry)
            this.$router.replace({ query: null })
          }
        }
      })
      .then(() => {
        this.loading = false
      })
    this.loading = false
  },
  methods: {
    async loadEntries() {
      this.entries = []
      const filter = {
        _and: [
          {
            user: {
              _eq: this.userId,
            },
          },
          {
            overwritten_by: {
              _null: true,
            },
          },
          {
            status: {
              _neq: 'rejected',
            },
          },
        ],
      }
      this.entries = await this.$cms.request(
        this.$readItems('schedule_history', {
          filter,
          fields: [
            'id',
            'status',
            'start',
            'type',
            'specialities.id',
            'specialities.speciality_id.id',
            'specialities.speciality_id.name',
            'facility.id',
            'facility.facility_id.id',
            'facility.facility_id.name',
            'end',
            'percentage',
            'extend_months',
            'request',
            'unit.id',
            'unit.short_name',
            'facility.id',
            'facility.name',
            'rotation_type',
            'schedule_section.id',
            'schedule_section.phase',
            'schedule_section.priority',
            'overwrite',
          ],
          sort: ['-start'],
          limit: this.teaser ? 4 : -1,
        })
      )
      if (!this.teaser && this.entries.length === 0) {
        this.showWarningNotification()
      }
    },
    async loadUnits() {
      if (this.user && this.user.facilities && this.user.facilities.length) {
        return this.$cms
          .request(
            this.$readItems('unit', {
              filter: {
                _and: [
                  {
                    facility: {
                      _eq: this.user.facilities[0].facility_id,
                    },
                  },
                ],
              },
              fields: [
                'id',
                'short_name',
                'long_name',
                'specialities.id',
                'specialities.speciality_id.id',
                'specialities.speciality_id.name',
                'rotation_types',
                'type',
              ],
              sort: ['short_name'],
            })
          )
          .then((response) => {
            this.units = response.map((unit) => {
              return {
                ...unit,
                name: this.formatParts([unit.short_name, unit.long_name]),
              }
            })
          })
      } else {
        this.units = []
      }
    },
    async loadFlow() {
      if (this.user && this.user.facilities && this.user.facilities.length) {
        return this.$cms
          .request(
            this.$readItems('schedule_flow_node', {
              filter: {
                _and: [
                  {
                    schedule_flow: {
                      facility: {
                        _eq: this.user.facilities[0].facility_id,
                      },
                    },
                  },
                  {
                    type: {
                      _eq: 'external',
                    },
                  },
                ],
              },
              fields: [
                'id',
                'facilities.id',
                'facilities.facility_id.id',
                'facilities.facility_id.name',
              ],
            })
          )
          .then((response) => {
            this.facilitiesInFlow = response
              .map((block) => {
                return block.facilities.map((f) => {
                  return f.facility_id.id
                })
              })
              .flat()
              // remove duplicate facilities
              .filter((value, index, self) => index === self.findIndex((f) => f === value))
          })
      }
    },
    addToEnd(duration) {
      let date = this.editEntry.end
      if (!date) {
        date = this.editEntry.start
      }
      if (!date) {
        date = new Date().toISOString().substring(0, 10)
      }
      const newDate = add(this.parseDate(date), duration)
      this.editEntry.end = newDate.toISOString().substring(0, 10)
      this.setEditEntryChanged()
    },
    setSpeciality(unitId) {
      if (unitId && unitId !== 'other') {
        const unit = this.units.find((unit) => unit.id === unitId)
        this.editEntry.specialities = unit.specialities.map((speciality) => {
          return { speciality_id: speciality.speciality_id }
        })
        this.editEntry.rotation_type = unit.rotation_types[0]
      }
    },
    addEntry() {
      this.v$.$reset()
      this.editEntry = { percentage: 100 }
      if (this.external || !this.getPremiumFeatures().includes('INTERNAL_ROTATION')) {
        this.toggleType = 'external'
      } else {
        this.toggleType = 'internal'
      }
      this.editEntryChanged = false
    },
    setEditEntry(entry) {
      this.v$.$reset()
      this.editEntry = {
        id: entry.id,
        start: entry.start,
        end: entry.end,
        percentage: entry.percentage,
        status: entry.status,
        facility: entry.facility,
        type: entry.type,
        specialities: entry.specialities,
        rotation_type: entry.rotation_type,
        unit: entry.unit ? entry.unit.id : null,
      }
      this.editEntryChanged = false
    },
    defaultChanged() {
      this.loadEntries()
      this.$emit('edit')
    },
    setEditEntryChanged(returnValue) {
      this.editEntryChanged = true
    },
    async saveEditEntry() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        // Find overlapping entries
        const overlappingEntries = this.findOverlappingEntries(
          this.editEntry.start,
          this.editEntry.end
        )
        if (overlappingEntries.length > 0) {
          // Calculate total percentage with the new entry
          const totalPercentage = this.calculateTotalPercentage(overlappingEntries, this.editEntry)
          // Check if total percentage exceeds 100%
          if (totalPercentage > 100) {
            this.$notificationStore.set({
              title: 'Zeitraum überschneidet sich',
              text: 'Der gewählte Zeitraum überschneidet sich mit einer bereits eingetragenen Erfahrung. Bitte passen Sie den Zeitraum an, um fortzufahren.',
              type: 'error',
            })
            return
          }
        }

        if (this.editEntry.status !== 'applied') {
          if (this.hasRole(['ADMINISTRATIVE_STAFF'])) {
            this.editEntry.status = 'confirmed'
          } else {
            this.editEntry.status = 'review'
          }
        }
        if (this.editEntry.specialities) {
          this.editEntry.specialities = this.editEntry.specialities.map((speciality) => {
            return { speciality_id: speciality.speciality_id.id }
          })
        }

        if (this.editEntry.facility && this.editEntry.facility?.id !== 'other') {
          this.editEntry.facility = this.editEntry.facility.id
        } else {
          this.editEntry.facility = null
        }

        if (this.editEntry.unit === 'other') {
          this.editEntry.unit = null
        }

        if (this.editEntry.id) {
          const id = this.editEntry.id
          delete this.editEntry.id
          await this.$cms.request(this.$updateItem('schedule_history', id, this.editEntry))
        } else {
          this.editEntry.user = this.userId
          await this.$cms.request(this.$createItem('schedule_history', this.editEntry))
        }
        this.$emit('edit')
        this.editEntry = null
        this.editEntryChanged = false
        this.loadEntries()
      }
    },
    findOverlappingEntries(newStartDate, newEndDate) {
      // Find existing entries that overlap with the new entry
      return this.entries.filter((entry) => {
        // Check if the intervals overlap using areIntervalsOverlapping
        if (this.editEntry.id && this.editEntry.id === entry.id) {
          return false
        }
        return areIntervalsOverlapping(
          { start: new Date(entry.start), end: new Date(entry.end) },
          { start: new Date(newStartDate), end: new Date(newEndDate) }
        )
      })
    },
    calculateTotalPercentage(overlappingEntries, newEntry) {
      // Calculate total percentage including the new entry and overlapping entries
      const sumOfPercentages = overlappingEntries.reduce((total, entry) => {
        return total + entry.percentage
      }, 0)
      return Number(sumOfPercentages) + Number(newEntry.percentage)
    },
    setSpecialityById(specialities) {
      if (this.editEntry?.facility?.id !== 'other') {
        this.editEntry.specialities = specialities.map((spec) => {
          return { speciality_id: spec.speciality_id }
        })
      }
    },
    async deleteEntry() {
      const id = this.editEntry.id
      await this.$cms.request(this.$deleteItem('schedule_history', id))
      this.$emit('edit')
      this.editEntry = null
      this.editEntryChanged = false
      this.confirmDeleteDialog = false
      this.loadEntries()
    },
    openDeleteDialog() {
      this.confirmDeleteDialog = true
    },
    showWarningNotification() {
      this.$notificationStore.set({
        title: 'Noch keine Rotationen angelegt',
        text: 'Falls bereits Rotationen durchlaufen wurden, tragen Sie diese bitte hier ein.',
        type: 'warning',
      })
    },
  },
}
</script>
